<template>
    <div class="insect_search_box">
        <div class="check_box">
           
                <el-date-picker
                v-model="searchParamDateVal"
                type="daterange"
                align="right"
                size="small"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                >
                </el-date-picker>
                <el-select v-model="reqData.baseId" placeholder="请选择基地" size="small" class="ml-10">
                    <el-option v-for="item of baseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <el-button type="primary" size="mini" @click="searchParamDateChange" class="ml-10" :loading="searchLoad" plain>统计</el-button>
                <ul class="boxsubBg dateType_box">
                    <li 
                    :class="[reqData.type == item.code? 'active':'', 'weather-cp']"
                    v-for="item of paramTimeList"
                    :key="item.code"
                    @click="paramTimeChange(item.code)"
                    >{{ item.name }}</li>
                </ul>

               
            
        </div>
        <div class="tools_box">
            
            <!-- <el-button type="warning" plain size="mini"  :loading="downLoad" @click="reqIotDeviceHistoryDataExport" class="downBut cp">导出</el-button> -->
        </div>
    </div>
</template>

<script>
    import { downloadFileByBase } from '@/libs/util'
    export default {
            props: {
                searchLoad: {
                    type: Boolean,
                    default: false
                }
            },
          data(){
            return {
                searchParamDateVal: [],
                paramTimeList:[
                   {
                       name: '今天',
                       code: 1
                   },
                    {
                       name: '三天',
                       code: 2
                   },
                   {
                       name: '本周',
                       code: 3
                   }
                   ,
                    {
                       name: '当月',
                       code: 4
                   },
                   {
                       name: '三月',
                       code: 5
                   }
                ],
                baseList: [],
                reqData: {
                    type: 1, 
                    sDate:'',
                    eDate: '',
                    baseId: 'all'
                },
                downLoad: false,
               
            }
          },
          created(){
            this.reqBaseList()
            this.$emit('searchInsectData', this.reqData);
          },
          methods: {
             // 参数历史数据 固定日期选择
             paramTimeChange(code) {
                
                this.$set(this.reqData,'type',code)
                this.searchParamDateVal= []
                this.$set(this.reqData,'sDate','')
                this.$set(this.reqData,'eDate','')
                this.$emit('searchInsectData', this.reqData);
            },
            // 参数历史数据 自定义日期选择
            searchParamDateChange(){
              if(this.searchParamDateVal.length){
           
                   this.$set(this.reqData,'sDate',this.searchParamDateVal[0])
                   this.$set(this.reqData,'eDate',this.searchParamDateVal[1])
                   this.$set(this.reqData,'type', 0)

              }else{
                   this.$set(this.reqData,'sDate','')
                   this.$set(this.reqData,'eDate','')
                   this.$set(this.reqData,'type', 1)
              }
              
              this.$emit('searchInsectData', this.reqData);
            },
            async reqIotDeviceHistoryDataExport() {
                this.downLoad = true
                const res = await this.$api.IotDeviceHistoryDataExport(this.reqData)
                
                if(res){
                    downloadFileByBase(res.fileBuffer, res.fileName)
                }
                setTimeout(() => {
        
                   this.downLoad = false
                }, 300);
            },
            async reqBaseList() {
                const res = await this.$api.EntPointInfoList()
                const defaultBase = [{name: '全部基地', id: 'all'}]
                this.baseList = [...defaultBase, ...res]
            }
         }
    }
</script>

<style lang="scss" scoped>
.insect_search_box{
    width: 100%;
    height: 100%;
    flex: 1;
    display: block;
    display: flex;
    align-items: center;
    margin-left: 20px;
    .check_box{
        flex: 1;
        display: flex;
      
        align-items: center;
        ul.dateType_box{
                display: flex;
            //    width: 214px;
                height: 30px;
                line-height: 30px;
                font-size: 12px;
                color: #fff;
                border-radius: 15px;
                margin-left: 15px;
                flex-shrink: 0;
                & > li {
                    padding: 0 18px;
                    border-radius: 15px;
                    
                }
                & > li:not(:last-child){
                    margin-right: 8px;
                }

        }
    }
    .tools_box{
        display: flex;
        font-size: 15px;
        & > div{
            width: 100px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border-radius: 8px;
        }
        & >div:first-child{
            border: 1px solid #6b7277;   
        }
        & > .downBut{
        //    margin-left: 12px;
            min-width: 90px;
            color: #fff;
            border: 1px solid #ffa800;
            background: rgba(#ffa800,0.2);
            margin-left: 15px;
        }
            
    }
}
</style>