<template>
    <div class="insect_box">
      
        <div class="insect_warn_box" v-if="warnList.length > 0">
            <warnBoard :listData ='warnList'></warnBoard>
            <el-button type="primary" size="mini" @click="toMoreWarn" class="ml-10" plain>更多</el-button>
        </div>
        <div class="box_1">
            <div class="tools_box">
                <div class="title">杀虫统计</div>
                <searchBox @searchInsectData='searchInsectData' :searchLoad='searchAreaLoad'></searchBox>
            </div>
            <div class="insect_box_1_content">
                <div class="content_left_box">
                    <animeImg></animeImg>
                    <p>
                        <span>杀虫总数：</span>
                        <span class="baseColor">{{ insectNub }}只</span>
                    </p>
                </div>
                <div class="content_right_box scrollbarMiniGary" v-if="insectList.length > 0">
                    <div class="content_right_box_item" v-for="item of insectList" :key="item.id">
                        <div class="content_right_box_item_left">
                            <span>{{ item.baseName }}</span>
                            <span class="single-ellipsis">{{ item.areaName }}</span>
                        </div>
                        <div class="content_right_box_item_right baseColor">
                            <span class="iconfont icon-tongwu-qingchong font-18"></span>
                            <p><span>{{ item.number }}</span>只</p>
                            
                        </div>

                    </div>
                </div>
                <div class="empty_box" v-else>
                    <img :src="noDataImg" alt="">
                    <p>暂无数据</p>
                </div>
            </div>
        </div>
        <div class="box_2">
            <div class="tools_box">
                <div class="title">虫情监测统计</div>
                <searchBox @searchInsectData="searchInsectDeatils" :searchLoad='searchInsectLoad'></searchBox>
            </div>
            <div class="box_2_content" v-if="insectInfoList.length > 0">
                <div class="box_2_content_left scrollbarMiniGary">
                    <ul class="insect_info_list">
                        <li v-for="item of insectInfoList" :key="item.libId" class="cp" @click="toInsectDeatils(item)">
                            <div class="item_box">
                                <span>{{ item.libName }}</span>
                                <p class="baseColor"><span>{{ item.number }}</span>只</p> 
                            </div>
                            <div class="arrow_icon">
                                <img src="@/assets/imgs/insect/default/arrow_right.png"  alt="" v-if="pestName == item.libName">
                            </div>
                            
                        </li>
                    </ul>
                </div>
              
                
                <div class="box_2_content_right">
                    <div class="title_box">
                        <img :src="bookIcon" alt="" />
                        <span class="baseColor font-16" style="margin-left: 5px;">{{ pestName || "虫情百科" }}</span>
                    </div>
                    <div class="insect_info_box" v-if="insectDeatilData">
                        
                        <div class="insect_info_box_item">
                            <p class="baseColor font-14">形态特征</p>
                            <div class="info_content scrollbarMiniGary">
                               {{ insectDeatilData.pestFeature }}

                            </div>
                        </div>
                        <div class="insect_info_box_item mh-30">
                            <p class="baseColor font-14">生活习性</p>
                            <div class="info_content scrollbarMiniGary">
                                {{ insectDeatilData.pestHabit }}
                            </div>
                        </div>
                        <div class="insect_info_box_item">
                            <p class="baseColor font-14">防治措施</p>
                            <div class="info_content scrollbarMiniGary">
                               {{ insectDeatilData.control }}

                            </div>
                        </div>
                    </div>
                    <div class="empty_box mt-20" v-else>
                        <img :src="noDataImg" alt="">
                        <p>暂无数据</p>
                    </div>
                </div>
            </div>
            <div class="empty_box" v-else>
                <img :src="noDataImg" alt="">
                <p>暂无数据</p>
            </div>
        </div>
    </div>
</template>

<script>
import warnBoard from "@/components/localComponent/board.vue";
import searchBox from "./searchBox.vue";
import animeImg from "./animeImg.vue";
import moreWarn from "./moreWarn.vue";
    export default {
        components: {
            warnBoard,
            searchBox,
            animeImg
        },
        data() {
            return {
                bookIcon: require("@/assets/imgs/insect/default/book_icon.png"),
                noDataImg: require('@/assets/imgs/noData/no_data2.png'),
                warnList: [],
                insectList: [],
                insectInfoList: [],
                insectDeatilData: null,
                pestName: '',
                searchAreaLoad: false,
                searchInsectLoad: false
            }
        },
        computed: {
            insectNub(){
                return this.insectList.reduce((total, item) => total + item.number, 0);
            }
        },
        mounted() {
            this.getWarnList();
        },
        methods: {
            toMoreWarn(){
                  this.showDialog(moreWarn,{},{width: '700px',title: '更多'})
            },
            async getWarnList() {
                const res = await this.$api.LogGetDevCodeAlarmPageList({ alarmType: 7,pageSize: 10,pageIndex: 1}); 
                this.warnList = res || [];
            },
            toInsectDeatils(item){
                this.pestName = item.libName;
                this.reqDeatilsData();
            },
            async searchInsectDeatils(data){
                
                this.searchInsectLoad = true;
                const reqData = {
                    baseId: data.baseId == 'all' ? '' : data.baseId,
                    sDate: data.sDate,
                    eDate: data.eDate,
                    type: data.type
                }
                
                const res = await this.$api.IotInsectDayStatisticsAreaDetailList(reqData);
                this.insectInfoList =  res || [];
                if(this.insectInfoList.length > 0){
                    console.log(this.insectInfoList[0],'insectList0');
                    this.pestName = this.insectInfoList[0].libName;
                    this.reqDeatilsData();
                }
                setTimeout(() => {
                    this.searchInsectLoad = false;
                }, 300);
            },
            async searchInsectData(data){
               
                const reqData = {
                    baseId: data.baseId == 'all' ? '' : data.baseId,
                    sDate: data.sDate,
                    eDate: data.eDate,
                    type: data.type
                }
                this.searchAreaLoad = true;
                const res = await this.$api.IotInsectDayStatisticsAreaList(reqData);
                this.insectList = res || [];
                setTimeout(() => {
                    this.searchAreaLoad = false;
                }, 300);
            },
            async reqDeatilsData(){
                const res = await this.$api.SysPestControlIotDetail({name: this.pestName});
                this.insectDeatilData = res ?.errmsg ? null : res 
                console.log(this.insectDeatilData,'insectDeatilData');
            }
        }
    }
</script>

<style lang="scss" scoped>
.insect_box{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .insect_warn_box{
        width: 100%;
        height: 40px;
        background: #1F2D37;
        border-radius: 10px;
        display: flex;
        align-items: center;
    }
    .tools_box{
        display: flex;
        align-items: center;
        .title{
            padding-left: 20px;
            font-size: 16px;
            font-weight: 600;
            color: #fff;
            border-left: 2px solid #00C497;
        }
    }
    .box_1{
        width: 100%;
        height: 300px;
        background: #1F2D37;
        border-radius: 10px;
        margin-top: 16px;
        padding: 20px 20px 20px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .insect_box_1_content{
            display: flex;
            flex: 1;
            min-height: 0;
            margin-top: 20px;
            .content_left_box{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 0 30px;
                
            }
            .content_right_box{
                flex: 1;
                min-width: 0;
                display: grid;
                grid-template-columns: repeat(7,1fr);
                grid-gap: 10px;
                grid-template-rows: 60px;
                grid-auto-rows: 60px; 
                .content_right_box_item{
                    display: flex;
                    align-items: center;
                    padding: 0 16px;
                    box-sizing: border-box;
                    background: rgba(40,53,63,0.8);
                    border-radius: 10px;
                    border: 1px solid #00FFC5;
                    font-size: 12px;
                    .content_right_box_item_left{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-align: left;
                        flex: 1;
                        min-width: 0;
                        margin-right: 10px;
                    }
                    .content_right_box_item_right{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        flex-shrink: 0;
                    }
                }
              
            }
           
        }
    }
    .box_2{
        flex: 1;
        min-height: 0;
        background: #1F2D37;
        border-radius: 10px;
        margin-top: 8px;
        padding: 20px 20px 20px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .box_2_content{
            display: flex;
            flex: 1;
            min-height: 0;
            margin-top: 20px;
        }
        .box_2_content_left{
            width: 300px;
            height: 100%;
            padding: 0 10px 0 20px;
            margin-right: 10px;
            .insect_info_list{
            
                box-sizing: border-box;
                li{
                    display: flex;
                    align-items: center;
                    & > .item_box{
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 30px 0 20px;
                        font-size: 14px;
                        flex: 1;
                        margin-right: 10px;
                    }
                    
                    .arrow_icon{
                        width: 30px;
                        height: 26px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                        // margin-right: 10px;
                    }
                    
                }
                li:nth-child(2n+1)> .item_box{
                    background: #15232D;
                    border-radius: 10px;
                }
            }
        }
       
        .box_2_content_right{
            height: 100%;
            flex: 1;
            min-width: 0;
            background: #28353F;
            border-radius: 10px;
            padding: 16px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            .title_box{
                display: flex;
                align-items: center;
                img{
                    width: 20px;
                    height: 20px;
                }
            }
            .insect_info_box{
                flex: 1;
                min-height: 0;
                margin-top: 20px;
                display: flex;
                .insect_info_box_item{
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                }
                .info_content{
                    font-size: 12px;
                    margin-top: 10px;
                    flex: 1;
                    min-height: 0;
                    padding-right: 5px;
                }
            }
        }
    }
}
.single-ellipsis{
	overflow: hidden;
	word-break: break-all;  /* break-all(允许在单词内换行。) */
	text-overflow: ellipsis;  /* 超出部分省略号 */
	display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
	-webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
	-webkit-line-clamp: 1; /** 显示的行数 **/
}
.empty_box{
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 140px;
        height: auto;
        margin-right: 20px;
    }
    p{
        font-size: 14px;
        color: #fff;
        margin-top: 10px;
    }
}
</style>