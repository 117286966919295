<template>
   <div class="search_tools_box"> 
      <div class="tools_box">
        <div class="search_box">
            <el-select v-model="reqData.quarter"  @change="quarterChange" size="medium" v-if="type === '1'" :clearable="false">
                <el-option v-for="item in quarterOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
            <el-select v-model="reqData.month"  @change="monthChange" size="medium" class="ml-10" v-if="type === '2'" :clearable="false">
                <el-option v-for="item in monthOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
            <el-date-picker
            v-if="type === '1' || type === '2'"
            @change="yearsChange"
            :clearable="false"
            class="ml-10 date_picker"
            popper-class="date_picker_popper"
            cellClassName="date_picker_cell"
            format="yyyy年"
            value-format="yyyy"
            size="medium"
            :editable="false"
            type="years"
            :picker-options="{
            disabledDate(time) {
                return time.getTime() > Date.now();
            }
            }"
            v-model="reqData.years"
            placeholder="选择一个或多个年">
            </el-date-picker>

            <el-date-picker
            v-if="type === '3'"
            @change="daysChange"
            :clearable="false"
            class="ml-10 date_picker"
            popper-class="date_picker_popper"
            size="medium"
            type="dates"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="{
                disabledDate(time) {
                return time.getTime() > Date.now();
                }
            }"
            v-model="reqData.days"
            placeholder="选择一个或多个日期">
            </el-date-picker>
            <el-cascader 
            class="ml-10"
            size="medium"
            style="width: 220px" 
            v-model="reqData.devId" 
            :options="qoqDevOption" 
            :props="{ emitPath: false }" 
            placeholder="请选择环比设备"></el-cascader>
            <el-button type="primary" @click="searchQoqData" size="small"  plain class="search_but_box"  :loading="searchLoad">查询</el-button>
            <el-button type="warning" plain size="small"  class="downBut cp" @click="resetData" >重置</el-button>
        </div>
        <!-- <el-button type="warning" plain size="small"  class="downBut cp" @click="animelDownload" >导出</el-button> -->
      </div>
      <transition name="fade">
        <template v-if="checksDataList.length">
            <div class="devList_box">
                <p>{{ checkName }}：</p>
                <el-tag
                    v-for="(tag,i) in checksDataList"
                    :key="tag.label || tag"
                    @close='tagClose(tag,i)'
                    class="tag_box"
                    closable
                    type="success">
                    {{tag.label || tag + (type == '3' ? '' : '年')}}
                </el-tag>
            </div>
        </template>
      </transition>
    </div>
</template>

<script>
    import { imgAddHost } from '@/libs/util.js'
    export default {
        props: {
            qoqDevOption: {
                type: Array,
                default: () => []
            },
            type: {
                type: String,
                default: '1'
            },
            searchLoad: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            type:{
                handler(val){
                    
              
                    if(val === '1'){
                        this.reqData.quarter = '1';
                        this.reqData.month = '';
                        this.reqData.days = [];
                        this.initYears();
                    }else if(val === '2'){
                        this.reqData.quarter = '';
                        this.reqData.days = [];
                        this.initMonth();
                        this.initYears();
                    }else if(val === '3'){
                        this.reqData.quarter = '';
                        this.reqData.month = '';
                        this.reqData.years = [];
                        this.initDays();
                    }
                    if(this.reqData.devId){
                        this.$emit('searchQoqData', this.reqData);
                    }
                    this.setChecksDataList();
                },
                immediate: true
            },
            qoqDevOption:{
                handler(val){
             
                    if(val.length > 0){
                        this.$set(this.reqData, 'devId', val[0].children[0].value);
                        this.$emit('searchQoqData', this.reqData);
                    }
                },
                deep: true,
                immediate: true
            }
        },
       data(){
         return {
            quarterOptions: [
                {
                    value: '1',
                    label: '一季度'
                },
                {
                    value: '2',
                    label: '二季度'
                },{
                    value: '3',
                    label: '三季度'
                },{
                    value: '4',
                    label: '四季度'
                }
                
            ],
            monthOptions: [
                {
                    value: '1',
                    label: '一月'
                },
                {
                    value: '2',
                    label: '二月'
                },{
                    value: '3',
                    label: '三月'
                },{
                    value: '4',
                    label: '四月'
                },{
                    value: '5',
                    label: '五月'
                },{
                    value: '6',
                    label: '六月'
                },{
                    value: '7',
                    label: '七月'
                },{
                    value: '8',
                    label: '八月'
                },{
                    value: '9',
                    label: '九月'
                },{
                    value: '10',
                    label: '十月'
                },{
                    value: '11',
                    label: '十一月'
                },{
                    value: '12',
                    label: '十二月'
                }
            ],
            checksDataList: [],
            reqData: {
                quarter: '',  //季度 1 : 一季度 2 : 二季度 3 : 三季度 4 : 四季度
                years: [], //年份
                month: '', //月份
                days: [], //日期
                devId: '', //设备
            },
            
         }
       },
       computed:{
          checkName(){
            if(this.type == '3'){
                return '环比日期';
            }else{
                return '环比年份';
            }
          }
       },
       methods: {
          setChecksDataList(){
             if(this.type == '3'){
                this.checksDataList = this.reqData.days;
             }else{
                this.checksDataList = this.reqData.years;
             }
          },
           tagClose(item,i){
                if(this.checksDataList.length <= 2){
                    this.$msg.warning("至少选择两个以上" + this.checkName);
                    return;
                }
                if(this.type == '3'){
                    this.reqData.days.splice(i,1);
                }else{
                    this.reqData.years.splice(i,1);
                }
           },
           async animelDownload() {
                const fileName = await this.$api.TraNewAnimalInfoExport();
                let url = imgAddHost(fileName);
                if (url) location.href = url;
            },
            rulesYears(){
                if (this.reqData.years && this.reqData.years.length < 2) {
                    this.$msg.warning("环比参数需要两个以上年份，请选择两个以上年份。");
                    return ;
                }
                return true;
            },
       
            rulesDays(){
                if (this.reqData.days && this.reqData.days.length < 2) {
                    this.$msg.warning("环比参数需要两个以上日期，请选择两个以上日期。");
                    return;
                }
                return true;
            },
            searchQoqData(){
                const isRule = this.type === '3' ? this.rulesDays() : this.rulesYears();
                if(!isRule){
                    return;
                }
                if (!this.reqData.devId) {
                  this.$msg.warning("请选择环比设备！");
                  return;
                }
                this.$emit('searchQoqData', this.reqData);
            },
            resetData(){
                this.initMonth();
                this.initYears();
                this.initDays();
                this.$set(this.reqData, 'devId', this.qoqDevOption[0].children[0].value);
                this.$emit('searchQoqData', this.reqData);
                this.setChecksDataList();
            },
            initMonth(){
                let nowMonth = this.$day().format('M');
                this.$set(this.reqData, 'month', nowMonth);
            },
            initYears(){
                let nowYear = this.$day().format('YYYY');
                let lastYear = this.$day().subtract(1, 'year').format('YYYY');
                this.$set(this.reqData, 'years', [nowYear, lastYear]);
               
            },
            initDays(){
                let nowDay = this.$day().format('YYYY-MM-DD');
                let yesterDay = this.$day().subtract(1, 'day').format('YYYY-MM-DD');
                this.$set(this.reqData, 'days', [yesterDay,nowDay]);
            },
            getQuarterOptions(){
                
            },
            quarterChange(val){
                console.log(val);
            },
            yearsChange(val){
                console.log(val);
                this.setChecksDataList();
            },
            monthChange(val){
                console.log(val);
            },
            daysChange(val){
                console.log(val);
                this.setChecksDataList();
            }
      }
    }
</script>

<style lang="scss" scoped>
.search_tools_box{
  
}
.tools_box{
    display: flex;
         justify-content: space-between ;
         align-items: center;
}
.search_box{
   .ml-10{
    margin-left: 10px;
   }
    .date_picker{
      border-radius: 5px;
    }
    .search_but_box{
        margin-left: 20px;
    }
}
.devList_box{
    width: 100%;
//  height: 40px;
    // margin-bottom: 10px;
    display: flex;
    align-items: center;
    margin-top: 6px;
    .tag_box{
    margin-right: 10px;
    margin-top: 5px;
    }
}


</style>