<template>
    <div class="qoq-box">
      <tabs @tabChange="tabChange"></tabs>
      <div class="qoq-box-content boxBg">
         
          <searchBox :type="qoqType" :qoqDevOption="qoqDevOption" @searchQoqData="searchQoqData" :searchLoad="searchLoad"></searchBox>
          <div class="ecahrts_content_box scrollbarMiniGary">
       
                <template v-if="contentEchartsList && contentEchartsList.length">
                        <ul class="echarts_list_box boxScroll wh100">
                            <li v-for="(item,i) of contentEchartsList" :key="i"  class="pageBg">
                                <p class="baseBorderLeftColor">{{ item.title }}</p>
                                <div :id="item.dome+i"></div>
                            </li>
                        </ul>
                </template>
                <template v-else>
                        <div class="wh100">
                            <img :src="noDataImg" alt="">
                            <p>暂无数据</p>
                        </div>
                </template>
                
            </div>
      </div>
    
    </div>
</template>

<script>
import tabs from './tabs.vue'
 
 import searchBox from './searchBox.vue'
 import { optionMoreline } from '@/libs/echartsOption'
    export default {
        props: {
            qoqDevOption: {
                type: Array,
                default: () => []
            }
        },
        data(){
            return {
                qoqType: '1',
                searchLoad: false,
                contentEchartsList: [],
                noDataImg: require('@/assets/imgs/noData/no_data2.png'),
                charts: []
            }
        },
        components: {
            tabs,searchBox
        },
        methods: {
            tabChange(id){
                console.log(id);
                this.qoqType = id;
            },
            
            async searchQoqData(data){
                try {
                    this.searchLoad = true;
                    const res = await this.$api.IotDeviceQoqAnalysis(data);
                    setTimeout(() => {
                        this.searchLoad = false;
                    }, 300);
                    // 注销图表
                    if(this.charts.length){
                        this.charts.forEach(item => {
                            item.dispose();
                        })
                        this.charts = [];
                    }
                    if (res && res.length) {
                        let arrList = [];
                        res.forEach((item) => {
                            arrList.push({
                                data: item,
                                dome: "yoy",
                                echartOption: optionMoreline,
                                title: `${item.paramName}(${item.paramUnit})`,
                            });
                        });
                        this.contentEchartsList = arrList;
                        if(arrList.length){
                            arrList.forEach((item,i) => {
                                this.$nextTick(()=> {
                                    this.echartInfo(item,i)
                                })
                                
                            })
                        }
                    } else {
                       
                        this.contentEchartsList = [];
                    }
                    return res;
                } catch {
                    this.searchLoad = false;
                    return false;
                }
            },
            echartInfo(item,i){
                
                let myChart = this.$echarts.init(document.getElementById(item.dome+i))
            
                myChart.setOption(item.echartOption(item.data),true)
                window.addEventListener("resize", function () {
                    myChart.resize();          
                })
                this.charts.push(myChart)
            },
     
        }
    }
</script>

<style lang="scss" scoped>
.qoq-box{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .qoq-box-content{
        flex: 1;
        min-height: 0;
       border-radius: 10px;
       padding: 20px;
       margin-top: 20px;
       display: flex;
       flex-direction: column;
       
       .ecahrts_content_box{
         flex: 1;
         min-height: 0;
        //  height: calc(100% - 60px);
        margin-top: 20px;
         .echarts_list_box{
             
             & > li:not(:first-child){
                margin-top: 20px;
             }
             & > li {
               width: 100%;
               height: 320px;
               border-radius: 5px;
               padding: 10px 0;
               & > p {
                   padding: 2px 0 2px 20px;
                  
               }
               & > div{
                   width: 100%;
                   height: calc(100% - 25px);
               }
             }
             
         }
         & > div {
             display: flex;
             align-items: center;
             justify-content: center;
             flex-direction: column;
             & > p {
                 margin-top: 30px;
             }
         }
     }
    }
}
</style>