<template>
    <div class="moreWarn_box">
          <div class="moreWarn_list scrollbarMiniGary">
               <div class="moreWarn_item" v-for="item in warnList" :key="item.id">
                   <div class="warn_content single-ellipsis">{{item.alarmContent}}</div>
                   <div class="warn_time">{{item.alarmTime}}</div>
               </div>
          </div>
          <div class="page_box">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="reqData.pageIndex"
                :page-size="reqData.pageSize"
                layout="total, prev, pager, next"
                class="pageInfo"
                background
                :total="picTotal">
            </el-pagination>
          </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                warnList:[
                    
                ],
                reqData:{
                    alarmType: 7,
                    pageSize: 10,
                    pageIndex: 1
                }
            }
        },
        methods:{
            async getWarnList(){
                const res = await this.$api.LogGetDevCodeAlarmPageList(this.reqData); 
                
                if (res && res.list.length) {
                    this.picTotal = res.totalCount;
                    this.warnList = res.list;
                } else this.warnList = [];
            },
             // 分页
            handleCurrentChange (page) {
                this.$set(this.reqData, "pageIndex", page);
                this.getWarnList();
            },
        }
    }
</script>

<style lang="scss" scoped>
.moreWarn_box{
    height: 400px;
    position: relative;
    .moreWarn_list{
        height: 100%;
      
        .moreWarn_item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:0 16px;
            height: 40px;
            font-size: 14px;
            color: #fff;
            .warn_content{
                flex: 1;
            }
            .warn_time{
                flex-shrink: 0;
            }
        }
        .moreWarn_item:nth-child(2n+1){
            background-color: #222F38;
            border-radius: 10px;
        }
    }
    .page_box{
        text-align: center;
        position: absolute;
        bottom: -34px;
        width: 100%;
    }
}
.single-ellipsis{
	overflow: hidden;
	word-break: break-all;  /* break-all(允许在单词内换行。) */
	text-overflow: ellipsis;  /* 超出部分省略号 */
	display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
	-webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
	-webkit-line-clamp: 1; /** 显示的行数 **/
}
</style>