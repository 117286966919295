<template>
   <ul class="animeImg_box">
        <li></li>
        <li></li>
        <li></li>
    </ul>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
     .animeImg_box{
        position: relative;
        width: 150px;
        height: 150px;
        margin: 0 auto;
        & > li:nth-child(1){
            width: 80px;
            height: 70px;
            background: url('../../../../../assets/imgs/intelligenPest/default/insect.png') no-repeat;
            background-size: 100% 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 100;
            transform: translateX(-50%) translateY(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            color: #2dca93;
            font-size: 18px;
            // font-weight: bold;
        }
        & > li:nth-child(2){
            width: 130px;
            height: 130px;
             background: url('../../../../../assets/imgs/intelligenPest/default/circle-inside.png') no-repeat;
           
            background-size: 100% 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            // transform: translateX(-50%) translateY(-50%);
            margin-left: -65px;
            margin-top: -65px;
            animation:backTurn 6s linear infinite;
            transform-origin: 50% 50%;
        }
        & > li:nth-child(3){
            width: 150px;
            height: 150px;
             background: url('../../../../../assets/imgs/intelligenPest/default/-s-circle-outside.png') no-repeat;
            background-size: 100% 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            // transform: translateX(-50%) translateY(-50%);
            margin-left: -75px;
            margin-top: -75px;
            animation:justTurn 6s linear infinite;
            transform-origin: 50% 50%;
        }
    }
    @keyframes justTurn{
        0%{-webkit-transform:rotate(0deg);}
        25%{-webkit-transform:rotate(90deg);}
        50%{-webkit-transform:rotate(180deg);}
        75%{-webkit-transform:rotate(270deg);}
        100%{-webkit-transform:rotate(360deg);}
    }
    @keyframes backTurn{
        0%{-webkit-transform:rotate(0deg);}
        25%{-webkit-transform:rotate(-90deg);}
        50%{-webkit-transform:rotate(-180deg);}
        75%{-webkit-transform:rotate(-270deg);}
        100%{-webkit-transform:rotate(-360deg);}
    }
</style>