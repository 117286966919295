<template>
  <div class="soil_main_box" v-loading="changLoading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(40, 53, 63, 0.8)">
    <div class="search_box">
      <el-select v-model="reqData.areaId" style="width: 300px" @change="handleFenceChange" size="medium" placeholder="请选择区域">
        <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
      </el-select>
      <div class="tools_box">
        <HnDateSearch :timeList="timeTypeList" :defaultData="reqData.timeType" @update="dateUpdate" />
      </div>
    </div>
    <div class="top_info_box">
      <div class="pageBg mr-20">
        <p class="baseBorderLeftColor">酸碱度分析</p>
        <div class="echarts_ground_box" v-show="isPhData">
          <div ref="phAverage"></div>
          <div ref="phSummary"></div>
        </div>

        <div class="noData" v-show="!isPhData">
          <img :src="noDataUrl" alt="" />
          <p>暂无数据</p>
        </div>
      </div>
      <div class="pageBg">
        <p class="baseBorderLeftColor">旱情分析</p>
        <div class="drought_box" v-if="phData">
          <div class="drought_item_box">
            <div class="icon_box"><img src="../../../../assets/imgs/analysis/default/circle1.png" alt="" /></div>
            <p>当前土壤温湿度</p>
            <div>
              <div class="param_info">
                <div><span class="bg1"></span> <span class="font-12 flex-shrink0">温度：</span></div>
                <div class="c1 font-16">{{ soilTempNub }}</div>
              </div>
              <div class="param_info mt-6">
                <div><span class="bg2"></span> <span class="font-12 flex-shrink0">湿度：</span></div>
                <div class="c2 font-16">{{ soilHumidityNub }}</div>
              </div>
            </div>
          </div>
          <div class="drought_arrow"></div>
          <div class="drought_item_box">
            <div class="icon_box"><img src="../../../../assets/imgs/analysis/default/circle2.png" alt="" /></div>
            <p>30天内降水</p>
            <div>
              <div class="param_info">
                <div><span class="bg1"></span> <span class="font-12 flex-shrink0">最大：</span></div>
                <div class="c1 font-16">{{ maxRainNub }}</div>
              </div>
              <div class="param_info mt-6">
                <div><span class="bg5"></span> <span class="font-12 flex-shrink0">最小：</span></div>
                <div class="c5 font-16">{{ miniRainNub }}</div>
              </div>
            </div>
          </div>
          <div class="drought_arrow"></div>
          <div class="drought_item_box">
            <img src="../../../../assets/imgs/analysis/default/index_icon.png" class="big_img" alt="" />
            <p class="state_title">旱情指数</p>
            <div class="state_text">
              <p>{{ paramIndex }}</p>
              <p :class="paramColor">{{ paramText }}</p>
            </div>
          </div>
        </div>
        <div class="noData" v-else>
          <img :src="noDataUrl" alt="" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>
    <div class="history_content_box boxScroll">
      <template v-if="devHistoryParamsList.length">
        <template v-for="(item, i) of devHistoryParamsList">
          <div class="pageBg" :key="item.collectorSensorId">
            <div class="left_history_box">
              <p class="baseBorderLeftColor">
                {{ item.paramName }}( <span>{{ item.paramUnit }}</span> )
              </p>
              <div>
                <div>
                  <div class="paramIcon">
                    <div>
                      <div :style="paramOutIconHanlde(i)"></div>
                      <span :class="item.paramIcon" :style="paramInIconHanlde(i)"></span>
                    </div>
                  </div>
                  <div>
                    <template v-if="item.paramName !== '风向'">
                      <p>
                        最新值：<span class="baseColor nubFont">{{ item.paramVal !== null ? item.paramVal + item.paramUnit : "无数据" }}</span>
                      </p>
                      <p>
                        最大值：<span class="nubFont">{{ item.maxVal !== null ? item.maxVal + item.paramUnit : "无数据" }}</span>
                      </p>
                      <p>
                        最小值：<span class="nubFont">{{ item.minVal !== null ? item.minVal + item.paramUnit : "无数据" }}</span>
                      </p>
                    </template>
                    <template v-else>
                      <div :style="paramInIconHanlde(i)">{{ paramValueHandle(item) }}</div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="right_history_box">
              <!-- <charts  :refName='"chart"+i' :options= 'item' class="chart_box"></charts> -->
              <charts :refName="'chart' + i" :options="item" class="chart_box"></charts>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <hn-emptyData text="暂无历史数据" size="180px" style="height: 100%"></hn-emptyData>
      </template>
    </div>
  </div>
</template>

<script>
  import { optionPhAverage, optionPhSummary } from "@/libs/echartsOption.js";
  import charts from "../../monitoring/components/charts.vue";
  export default {
    components: {
      charts,
    },
    data() {
      return {
        areaList: [],
        reqData: {
          areaId: "",
          sTime: "",
          eTime: "",
          timeType: 5,
        },
        timeTypeList: [
          {
            name: "今天",
            code: 1,
          },
          {
            name: "近三天",
            code: 2,
          },
          {
            name: "近七天",
            code: 3,
          },
          {
            name: "近一个月",
            code: 4,
          },
          {
            name: "近三个月",
            code: 5,
          },
        ],
        phData: null,
        noDataUrl: require("@/assets/imgs/noData/no_data2.png"),
        devHistoryParamsList: [],
        changLoading: false,
        paramColorList: [
          {
            iconUrl: require("@/assets/imgs/monitoring/default/circle.png"),
            iconColr: "#00ffc5",
          },
          {
            iconUrl: require("@/assets/imgs/monitoring/default/circle1.png"),
            iconColr: "#00ccff",
          },
          {
            iconUrl: require("@/assets/imgs/monitoring/default/circle2.png"),
            iconColr: "#ffa800",
          },
          {
            iconUrl: require("@/assets/imgs/monitoring/default/circle3.png"),
            iconColr: "#a54ed7",
          },
          {
            iconUrl: require("@/assets/imgs/monitoring/default/circle4.png"),
            iconColr: "#435ae6",
          },
        ],
      };
    },
    created() {
      this.reqAreaList();
    },
    computed: {
      paramValueHandle() {
        return (item) => {
          if (item.paramName.includes("风向")) {
            let valArr = [
              {
                max: 112.5,
                min: 67.5,
                name: "北风",
              },
              {
                max: 157.5,
                min: 112.5,
                name: "西北风",
              },
              {
                max: 202.5,
                min: 157.5,
                name: "西风",
              },
              {
                max: 247.5,
                min: 202.5,
                name: "西南风",
              },
              {
                max: 292.5,
                min: 247.5,
                name: "南风",
              },
              {
                max: 337.5,
                min: 292.5,
                name: "东南风",
              },
              {
                max: 22.5,
                min: 337.5,
                name: "东风",
              },
              {
                max: 67.5,
                min: 22.5,
                name: "东北风",
              },
            ];
            let val = parseInt(item.paramVal);
            let resultName = "无风";

            valArr.some((item) => {
              if (val > 337.5 || val <= 22.5) {
                resultName = "东风";
                return true;
              }
              if (val > item.min && val <= item.max) {
                resultName = item.name;
                return true;
              }
            });
            return resultName;
          } else {
            return item.paramVal;
          }
        };
      },
      paramInIconHanlde() {
        return (index) => {
          let i = this.paramColorList.length > index ? index : index % 4;
          let data = this.paramColorList[i];
          return {
            color: data.iconColr,
            fontSize: "30px",
          };
        };
      },
      paramOutIconHanlde() {
        return (index) => {
          let i = this.paramColorList.length > index ? index : index % 4;
          let data = this.paramColorList[i];
          return {
            backgroundImage: `url(${data.iconUrl})`,
            backgroundRepeat: "no-repeat",
          };
        };
      },
      isPhData() {
        return this.phData?.phAverage ? true : false;
      },
      // 土壤温度
      soilTempNub() {
        return this.phData?.temp + "℃" || 0 + "℃";
      },
      // 土壤湿度
      soilHumidityNub() {
        return this.phData?.humidity + "%" || 0 + "%";
      },
      // 最大降雨
      maxRainNub() {
        return this.phData?.rainfallMax + "mm" || 0 + "mm";
      },
      // 最小降雨
      miniRainNub() {
        return this.phData?.rainfallMin + "mm" || 0 + "mm";
      },
      paramIndex() {
        return this.phData.hasOwnProperty("index") ? this.phData.index : "无";
      },
      // 旱情指数
      paramText() {
        const index = this.phData.hasOwnProperty("index") ? this.phData.index : -1;
        const info = [
          {
            mini: 0,
            max: 3,
            name: "严重干旱",
          },
          {
            mini: 4,
            max: 5,
            name: "中度干旱",
          },
          {
            mini: 6,
            max: 8,
            name: "轻度干旱",
          },
          {
            mini: 9,
            max: 10,
            name: "正常",
          },
        ];
        let text = "";
        info.some((item) => {
          if (!text) {
            if (item.mini <= index && item.max >= index) {
              text = item.name;
              return true;
            }
          }
        });
        return text || "无状态";
      },
      paramColor() {
        const index = this.phData.hasOwnProperty("index") ? this.phData.index : -1;
        const info = [
          {
            mini: 0,
            max: 3,
            name: "c4",
          },
          {
            mini: 4,
            max: 5,
            name: "c3",
          },
          {
            mini: 6,
            max: 8,
            name: "c2",
          },
          {
            mini: 9,
            max: 10,
            name: "c1",
          },
        ];
        let text = "";
        info.some((item) => {
          if (!text) {
            if (item.mini <= index && item.max >= index) {
              text = item.name;
              return true;
            }
          }
        });
        return text || "c5";
      },
    },
    methods: {
      async reqIotDevPHDataList() {
        const res = await this.$api.IotDevPHDataList(this.reqData);
        //   let mockData = {
        //     "phAverage": 5.7,
        //     "phDataClassifys": [
        //        {
        //             value: 12,
        //             name: '强酸'
        //         },
        //         {
        //             value: 121,
        //             name: '弱酸'
        //         },
        //         {
        //             value: 20,
        //             name: '中性'
        //         },
        //         {
        //             value: 41,
        //             name: '弱碱'
        //         },
        //         {
        //             value: 328,
        //             name: '强碱'
        //         }
        //     ],
        //     "temp": 0,
        //     "humidity": 0,
        //     "rainfallMax": 0,
        //     "rainfallMin": 0,
        //     "index": 0
        //   }
        //   res = mockData
        this.phData = res && !res?.errmsg ? res : null;

        if (this.isPhData) {
          this.$nextTick(() => {
            this.echartInit(optionPhAverage(res.phAverage, res.phType), "phAverage");
            this.echartInit(optionPhSummary(res.phDataClassifys), "phSummary");
          });
        }
      },
      //  历史设备数据查询
      async reqIotDeviceHistoryDataList() {
        this.changLoading = true;
        const res = await this.$api.IotDeviceHistoryDataList(this.reqData);
        setTimeout(() => {
          this.changLoading = false;
        }, 500);
        if (res) {
          if (res.devHistoryParams && res.devHistoryParams.length) {
            res.devHistoryParams.forEach((item) => {
              item.editVisible = false;
              item.seeVisible = false;
            });
            this.devHistoryParamsList = res.devHistoryParams;
          } else {
            this.devHistoryParamsList = [];
          }
        }
      },
      dateUpdate(data) {
        Object.assign(this.reqData, data);
        this.reqIotDevPHDataList();
        this.reqIotDeviceHistoryDataList();
      },
      handleFenceChange() {
        this.reqIotDevPHDataList();
        this.reqIotDeviceHistoryDataList();
      },
      async reqAreaList() {
        const res = await this.$api.EntAreaDeviceList();
        this.areaList = res || [];
        if (this.areaList && this.areaList.length) {
          this.reqData.areaId = this.areaList[0].id;
          this.reqIotDevPHDataList();
          this.reqIotDeviceHistoryDataList();
        }
      },
      echartInit(option, domeName) {
        let myChart = this.$echarts.init(this.$refs[domeName]);

        myChart.setOption(option);

        window.addEventListener("resize", function () {
          myChart.resize();
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .noData {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
    font-size: 14px;
    & > img {
      width: 120px;
      margin-right: 25px;
    }
  }
  .soil_main_box {
    .search_box {
      display: flex;
      margin-bottom: 20px;
      .tools_box {
        margin-left: 30px;
      }
    }
  }
  .top_info_box {
    height: 280px;
    display: flex;
    justify-content: space-between;
    & > div {
      flex: 1;
      height: 100%;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      padding: 10px 0;
      box-sizing: border-box;
      & > p {
        padding: 2px 0 2px 20px;
      }
      & > div {
        width: 100%;
        height: calc(100% - 25px);
      }
      .drought_box {
        display: flex;
        padding: 20px 40px;
        box-sizing: border-box;
        .c1 {
          color: var(--el-color-primary) !important;
        }
        .bg1 {
          background-color: var(--el-color-primary) !important;
        }
        .c2 {
          color: #4699ff !important;
        }
        .bg2 {
          background-color: #4699ff !important;
        }
        .c3 {
          color: #ffa800 !important;
        }
        .bg3 {
          background-color: #ffa800 !important;
        }
        .c4 {
          color: #ff7e00 !important;
        }
        .bg4 {
          background-color: #ff7e00 !important;
        }
        .c5 {
          color: #999999 !important;
        }
        .bg5 {
          background-color: #999999 !important;
        }
        & > div {
          height: 100%;
        }
        .drought_item_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .flex-shrink0 {
            flex-shrink: 0;
          }
          .icon_box {
            width: 92px;
            height: 120px;
            & > img {
              width: 92px;
              height: 92px;
            }
          }

          .big_img {
            width: 162px;
            height: 120px;
          }
          & > p {
            font-size: 14px;
            margin-bottom: 16px;
          }
          // & > .state_title{
          //     margin: 10px 0;
          // }

          .param_info {
            display: flex;
            align-items: center;
            & > div {
              color: #fff;
              display: flex;
              align-items: center;
              margin-right: 10px;
              & > span:first-child {
                display: block;
                width: 3px;
                height: 12px;
                margin-right: 10px;
                flex-shrink: 0;
              }
            }
          }
          .state_text {
            text-align: center;
          }
        }
        .drought_arrow {
          width: 95px;
          height: 100px;
          background: url("../../../../assets/imgs/analysis/default/arrow.png") no-repeat;
          background-size: cover;
          background-position: center center;
          margin: 0 30px;
        }
      }
      .echarts_ground_box {
        display: flex;
        & > div {
          flex: 1;
          height: 100%;
        }
      }
    }
    .mr-20 {
      margin-right: 20px;
    }
  }
  .history_content_box {
    margin-top: 20px;
    height: 400px;
    & > div {
      width: 100%;
      height: 180px;
      margin-bottom: 10px;
      border-radius: 8px;
      display: flex;
      .left_history_box {
        width: 490px;
        height: 100%;
        flex-shrink: 0;
        & > p {
          margin-top: 20px;
          padding-left: 20px;
        }
        & > div {
          width: 100%;
          height: calc(100% - 41px);
          display: flex;
          justify-content: center;
          align-items: center;
          & > div {
            width: 220px;
            height: 90px;
            background: url("../../../../assets/imgs/monitoring/default/label.png");
          }
          & > div:first-child {
            display: flex;
            align-items: center;
            & > div:last-child {
              flex: 1;
              font-size: 14px;
              & > p {
                padding: 2px 0;
              }
              & > p:nth-child(2) > span {
                color: #ffa800;
              }
            }
            .paramIcon {
              width: 100px;
              & > div {
                width: 60px;
                height: 60px;
                position: relative;
                margin: 0 auto;
                & > div {
                  width: 100%;
                  height: 100%;
                  animation: turn 4s linear infinite;
                }
                & > span {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
          & > div:last-child {
            margin-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            .flex_content {
              font-size: 14px;
              & > div {
                padding: 2px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .warn_box {
                  display: flex;
                }
                //   .edit_box{
                //       display: inline-block;
                //       margin-left: 5px;
                //   }
                //   .cheakwarn{
                //       display: inline-block;
                //       margin-left: 5px;

                //   }
              }
              .warinColor {
                color: #ffa800;
              }
            }
          }
        }
      }
      .right_history_box {
        flex: 1;
        height: 100%;
      }
    }
  }
</style>
