<template>
    <div class="tabs-box">
      <div v-for="item in tabsList" :key="item.id" :class="['tabs-item',activeCode === item.id ? 'active_tab' : '']" @click="handleClick(item.id)">
        <div class="tabs-item-title">{{ item.name }}</div>
      </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                activeCode: '1',
                tabsList: [
                    {
                        name: '季度环比',
                        id: '1'
                    },
                    {
                        name: '月度环比',
                        id: '2'
                    },
                    {
                        name: '日环比',
                        id: '3'
                    }
                ]
            }
        },
        methods: {
            handleClick(id){
                this.activeCode = id;
                this.$emit('tabChange',id);
            }
        }
    }
</script>

<style lang="scss" scoped>
.tabs-box{
    // height: 40px;
    display: flex;
    justify-content: center;
    .tabs-item{
        
        padding-bottom: 8px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
       
    }
    .active_tab{
        color: #12D0A9;
        border-bottom: 1px solid #12D0A9;
        & > ::after{
            content: '';
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 4px solid #12D0A9;
        }
    }
    .tabs-item:nth-child(2){
        margin:0 40px;
    }
}
</style>